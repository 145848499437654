<template>
  <div class="info-container">
    <div class="info-box">
      <!-- <h2>{{title}}</h2> -->
      <h1>{{subtitle}}</h1>
      <!-- <p v-if="!notAvailable">£175</p> -->
      
      <div v-if="notAvailable" class="button-container coming-soon-button">
        <button disabled>Available @ 20:00</button>
      </div>

      <div v-else class="input-container">
        <div class="select-container">
          <!-- <select class="size-select" ref="selectedItem">
            <option value="" disabled selected>Size</option>
            <option v-for="size in sizes" :value="size.variantId" id="" :key="size" >{{size.eu}}</option>
          </select> -->
        </div>
        
        <a class="button-container" :href="buttonLink" target="_blank">
          <button>{{buttonText}}</button>
        </a>
      </div>
    </div>
  </div>
</template>
  
<script>
    export default {
        name: 'ProductInfo',
        props: {
          title: String,
          subtitle: String,
          buttonText: String,
          buttonLink: String,
        },
        methods:{

          addToCart: () => {

          },

        },
        data: () => ({
          notAvailable: false, 
          currentSize: '',
          sizes: [
            {
              eu: '37',
              variantId: ''
            },
            {
              eu: '38',
              variantId: ''
            },
            {
              eu: '39',
              variantId: ''
            },{
              eu: '40',
              variantId: ''
            },
            {
              eu: '41',
              variantId: ''
            },
            {
              eu: '42',
              variantId: ''
            },
            {
              eu: '43',
              variantId: ''
            },
            {
              eu: '44',
              variantId: ''
            },
            {
              eu: '45',
              variantId: ''
            },
            {
              eu: '46',
              variantId: ''
            }
          ],
        }),
    }
</script>
  