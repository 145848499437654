<template>
  <div class="logo-container">
      <img alt="GTX Logo" src="./assets/logo.svg">
    </div>
    <div class="product-container">
      <div class="display-container">
        <img src="./assets/tears-gtx-shoe.webp" alt="Side shot of shoe">
    </div>
      <ProductInfo title="GTX x TEARS" subtitle="BULKY GTX" buttonText="GTX BULKY SHOE" buttonLink="https://shop.tears.rip/products/goontex-bulky"/>    
    </div>
    <div class="product-container">
      <div class="display-container">
        <img src="./assets/gtx-f6f-tee.webp" alt="Side shot of shoe">
    </div>
      <ProductInfo title="GTX x F6F" subtitle="GTX F6F TEE" buttonText="GTX F6F TEE" buttonLink="https://www.thef6fproject.com/product/8378079/goontex-tee"/>    
    </div>
</template>

<script>
//https://shop.tears.rip/cart/add?id=VARIANTID&quantity=1

// import ProductImage from './components/ProductImage.vue'
import ProductInfo from './components/ProductInfo.vue'

export default {
  name: 'App',
  components: {
    // ProductImage,
    ProductInfo
}
}
</script>